// @flow
/**
 * Contains API helpers.
 */
import { API, Auth } from 'aws-amplify';

export const ConnectAdminApi = {
  CHEMICAL_API: 'adminChemicalAPI',
  EQUIPMENT_API: 'adminEquipmentAPI',
  INSPECTION_API: 'adminInspectionAPI',
  COURSE_API: 'adminCourseAPI',
  COMMON_API: 'adminCommonAPI',
  USER_API: 'adminUserAPI',
  ORG_API: 'adminOrgAPI',
  JOB_PLANNER_API: 'adminJobPlannerAPI',
  EHS_TIPS_API: 'adminEhsTipsAPI',
  EXTERNAL_CERTIFICATION_API : 'CertificationApi',
  CONTRACTOR_API : 'contractorApi'
}

export const ConnectApi = {
  INSPECTION_API: 'inspectionAPI',
  COURSE_API: 'courseAPI',
  ADMIN_API: 'connectAdminAPI',
  USER_API: 'connectUserAPI',
  EQUIPMENT_API: 'connectEquipmentAPI',
  CHEMICAL_API: 'connectChemicalAPI',
  REPORT_API : 'reportAPI',
  SITE_VALUE_API : 'siteValueAPI',
  NATIONAL_MAP_API: 'nationalMapApi'
}


// --------- GENERIC API ------------------
export async function getApi(api, path, params) {
  let options = (params) ? params : {};
  await Auth.currentSession().then((session) => {
    let jwtToken = session.idToken.jwtToken;
    options = {
      ...options,
      headers: {
        Authorization: jwtToken
      }
    }
  });
  return API.get(api, path, options);
}

export async function putApi(api, path, body) {
  let options = { body };
  await Auth.currentSession().then((session) => {
    let jwtToken = session.idToken.jwtToken;
    options = {
      ...options,
      headers: {
        Authorization: jwtToken
      }
    }
  });
  return API.put(api, path, options);
}

export async function postApi(api, path, body) {
  let options = { body };
  await Auth.currentSession().then((session) => {
    let jwtToken = session.idToken.jwtToken;
    options = {
      ...options,
      headers: {
        Authorization: jwtToken
      }
    }
  });
  try{
    const result = await API.post(api, path, options);
    return result;
  }catch(err){
    if(err.response && err.response.data && err.response.data.error){
      console.error('ERROR:',err.response.data.error);
      throw err.response.data.error;
    }else{
      console.error('ERROR:',err);
      throw err;
    }
  }
}
