export const CONFIG = {
    cognitoUserPoolId: 'us-west-2_osCZOviCl',
    userPoolWebClientId: '7gt9spt4sti7v9l0o1k7jpuepd',
    cognitoIdentityPoolId: 'us-west-2:56a8cd09-217a-46bf-9290-4e201cc9bcae',
    awsRegion: 'us-west-2',
    s3Region: 'us-west-1',

    baseApiUrl: 'https://www.eparconnectserver.com/qa-',
    apiStage: 'qa',

    s3HostUrl: 'https://s3-us-west-1.amazonaws.com',
    chemicalFilesBucket: "qa-us-chemical-files",
    equipmentFilesBucket: "qa-us-connect-equipment-manuals",
    courseFilesBucket: "qa-us-connect-courses",
    orgPublicBucket: 'qa-us-connect-org-public-data',
    nationalMapBucket: 'qa-us-national-map-site-data',
    reportUploadBucket: 'qa-us-connect-report',
    ehsSlideBucket: 'qa-us-connect-ehs-slide-images',
    commonDocumentsBucket: 'qa-us-connect-common-docs',

    reportEndPoint: "https://qa-reporting.eparconnectserver.com",

    adminAPIEndPoint: "https://www.eparconnectserver.com/qa-admin-api/qa",

    connectWebUrl: "https://www.qa-v2.eparconnect.com",
      // tinyMce free version intergrated. dev and qa api keys generated using custom email and prod api key generated using 'nipun@smbapps.com.au' email
    tinyMceApiKey : "cc7ftamcri7qplcy2miqxf1l4hz5cyw67a0hlilszu2vojdy",
    mapboxglAccessToken: 'pk.eyJ1IjoiYXNpdGhhOTciLCJhIjoiY2x3dDd6Z2h6MDB2djJqbzh6djFrbmpkbyJ9.e8hhdozq53qFs3C43fcteQ'
}