import React, { Component } from 'react';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setSelectedMenu } from '../../actions/commonActions';
import ContractorListView from './containers/ContractorListView';
import ContractorDetails from './containers/ContractorDetails';

class ContractorHome extends Component {
    constructor(props) {
        super(props);
        this.props.setSelectedMenu(['contractors']);
    }

    getView = (route) => {
        switch (route.match.params.page) {
            case 'contractor_details':
                return <ContractorDetails id={route.match.params.id} orgId={route.match.params.orgId} isAdmin={route.match.params.isAdmin}/>;
            default:
                return <ContractorListView />;
        }
    }

    render() {
        return (
            <Switch>
                <Route path={'/contractors/:page/:id/:orgId/:isAdmin'} render={this.getView} />
                <Route path={'/contractors'} render={this.getView} />
                <Redirect to='/contractors' />
            </Switch>
        )
    }

}

function mapStateToProps(state, props) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setSelectedMenu: bindActionCreators(setSelectedMenu, dispatch)
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractorHome));