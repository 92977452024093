import React, { Component } from "react";
import { getOrgList, setBreadcrumb } from '../../../actions/commonActions';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from "redux";
import { Card, Form, Row, Tag} from "antd";
import DetailsTable from "../../../components/DetailsTable";
import { getContractorDetails } from "../actions/contractorActions";
import ConnectLoading from "../../../components/ConnectLoading";
import { ContractorListItem } from "../ContractorListItemBuilder";
import { ListWidget } from "../../../components/ListWidget";
import { ORG_STATUS, USER_STATUS, USER_TYPE } from "../constants";

class ContractorDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOrg: null,
            loading: false,
            users: [],
            contractorUser: undefined
        };

        this.props.setBreadcrumb("Contractor Details", [
            { route: '/contractors', label: 'Contractors' },
            { route: '', label: 'Contractor Details' },
        ]);
    }

    getOrgName = (orgList, orgId) => {
        const selectedOrgData = orgList.find(org => org.org_id ===orgId);
        return (selectedOrgData.name);
    }

    setUserStatus = (status) => {
        if(status) {
            if(status === USER_STATUS.ACTIVE) {
                return (<Tag color="green">Active</Tag>)
            } else{ 
                return (<Tag color="red">Inactive</Tag>)
            }
        } else {
            return (<Tag color="orange">Not Registered</Tag>)
        }
    }

    componentDidUpdate = async (prevProps) => {
        const {orgId, id, isAdmin} = this.props;
        if(prevProps.id !== this.props.id) {
            this.setState({loading: true, users:[]})
            await this.setContractorDetails(orgId, id, isAdmin);
            this.setState({loading: false})
        }
    }

    setContractorDetails = async(orgId, id, isAdmin) => {
        const res = await getContractorDetails({orgId, id, isAdmin});
        if(isAdmin === USER_TYPE.ADMIN) {
            res.adminContractor.orgName = this.getOrgName(this.props.orgList, orgId);
            this.setState({contractorUser : res.adminContractor, users : res.userList})
        } else {
            if(orgId === ORG_STATUS.NOT_ASSIGNED) {
                res.contractorUser.orgName = 'Not Assigned yet'
            } else {
                res.contractorUser.orgName = this.getOrgName(this.props.orgList, orgId);
            }
            this.setState({contractorUser : res.contractorUser})
        }
    }

    componentDidMount = async() => {
        const {orgId, id, isAdmin} = this.props;
        this.setState({loading: true})
        await this.props.getOrgList();
        await this.setContractorDetails(orgId, id, isAdmin);
        this.setState({loading: false});
    }

    setFields = (values) => {
        const compliantStatus = values.compliance === 0 ? 'Compliant' : 'Non Compliant';
        const email = values.email || values.contact_email;
        const name = (values.first_name && values.last_name) ? `${values.first_name} ${values.last_name}` : values.contractor_name;
        
        const fields = [
            { key: "Name", value: name },
            { key: "Organisation", value: values.orgName },
            { key: "Email", value: email },
            { key: "Status", value: this.setUserStatus(values.status) },
            { key: 'Compliant Status', value: compliantStatus}
        ];
        if(this.props.isAdmin === USER_TYPE.ADMIN) {
            fields.push(
                { key: "ABN", value: values.registration_number || 'Yet to be specified' },
                { key: "Profile Name", value: values.profile_name || '-' },
                { key: "Contact Number", value: values.contact_number },
                { key: "Business Name", value: values.contractor_name },
            )
        } 
        return fields;
    }

    getUsersForListView = () => {
        const orgList = this.props.orgList;
        const contractorUsers =  this.state.users.filter(user => user.isAdmin !==USER_TYPE.ADMIN);
        let userList = contractorUsers.map((user) => {
            let builder = new ContractorListItem.Builder(user, orgList);
            return builder.build();
        });
        return userList;
    }

    render() {
        if(this.state.loading || !this.state.contractorUser) {
            return (<ConnectLoading />)
        } else {
            return (
                <div>
                    <Card>
                        <div className="pure-g">
                            <div className="pure-u-1">
                                <div>
                                    <div className="pure-u-1">
                                        <DetailsTable dataSource={this.setFields(this.state.contractorUser)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                    {this.state.users.length ? (
                        <>
                            <div style={{marginTop:'10px', marginBottom:'10px', fontSize:'20px', fontWeight:'600'}}><p>Employees</p></div>
                            <div className="pure-g" style={{ marginTop: 10 }}>
                                <div className="pure-u-1">
                                    <ListWidget data={this.getUsersForListView()} loading={this.state.loading} />
                                </div>
                            </div>
                        </>
                    ) : null}
                </div>
            )
        }
    }
}

function mapStateToProps(state, props) {
    return {
        orgList: state.getIn(['orgState', 'orgList']),
        orgIndex: state.getIn(['orgState', 'orgIndex']),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setBreadcrumb: bindActionCreators(setBreadcrumb, dispatch),
        getOrgList: bindActionCreators(getOrgList, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form.create()(ContractorDetails )));