/**
 * configure aws amplify client
 */

import Amplify from 'aws-amplify';
import { CONFIG } from '../config/app-config';
import { ConnectAdminApi, ConnectApi } from './api-utils';

const { cognitoUserPoolId, userPoolWebClientId, cognitoIdentityPoolId, awsRegion, baseApiUrl, apiStage } = CONFIG;

export function awsConfigInitialise() {
    console.log('cognitoIdentityPoolId:', cognitoIdentityPoolId);

    Amplify.configure({
        Auth: {
            // REQUIRED - Amazon Cognito Identity Pool ID
            identityPoolId: cognitoIdentityPoolId,
            // REQUIRED - Amazon Cognito Region
            region: awsRegion,
            // OPTIONAL - Amazon Cognito User Pool ID
            userPoolId: cognitoUserPoolId,
            // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
            userPoolWebClientId: userPoolWebClientId,
            // OPTIONAL - Enforce user authentication prior to accessing AWS resources or
            // not
            mandatorySignIn: true,
            // OPTIONAL - Configuration for cookie storage cookieStorage: { // REQUIRED -
            // Cookie domain (only required if cookieStorage is provided)     domain:
            // '.yourdomain.com', // OPTIONAL - Cookie path     path: '/', // OPTIONAL -
            // Cookie expiration in days     expires: 365, // OPTIONAL - Cookie secure flag
            //    secure: true }
        },
        Storage: {
            region: CONFIG.s3Region, //Specify the region your bucket was created in;
            identityPoolId: cognitoIdentityPoolId //Specify your identityPoolId for Auth and Unauth access to your bucket;
        },
    });
}

export async function awsAmplifyApiInit() {
    await Amplify.configure({
        API: {
            endpoints: [
                {
                    name: ConnectAdminApi.CHEMICAL_API,
                    endpoint: `${baseApiUrl}admin-chemical-api`
                },
                {
                    name: ConnectAdminApi.EQUIPMENT_API,
                    endpoint: `${baseApiUrl}admin-equipment-api`
                },
                {
                    name: ConnectAdminApi.INSPECTION_API,
                    endpoint: `${baseApiUrl}admin-inspection-api`
                },
                {
                    name: ConnectAdminApi.COURSE_API,
                    endpoint: `${baseApiUrl}admin-course-api`
                },
                {
                    name: ConnectAdminApi.COMMON_API,
                    endpoint: `${baseApiUrl}admin-common-api`
                },
                {
                    name: ConnectAdminApi.USER_API,
                    endpoint: `${baseApiUrl}admin-user-api`
                },
                {
                    name: ConnectAdminApi.ORG_API,
                    endpoint: `${baseApiUrl}admin-org-api`
                },
                {
                    name: ConnectAdminApi.JOB_PLANNER_API,
                    endpoint: `${baseApiUrl}admin-job-plan-api`
                },
                {
                    name: ConnectAdminApi.EHS_TIPS_API,
                    endpoint: `${baseApiUrl}admin-ehs-tips-api`
                },
                {
                    name: ConnectAdminApi.EXTERNAL_CERTIFICATION_API,
                    endpoint: `${baseApiUrl}admin-certification-api`
                },
                {
                    name: ConnectAdminApi.CONTRACTOR_API,
                    endpoint: `${baseApiUrl}admin-contractor-api`
                },


                {
                    name: ConnectApi.INSPECTION_API,
                    endpoint: `${baseApiUrl}inspection-api/${apiStage}/orgs`
                },
                {
                    name: ConnectApi.COURSE_API,
                    endpoint: `${baseApiUrl}course-api/${apiStage}/orgs`
                },
                {
                    name: ConnectApi.ADMIN_API,
                    endpoint: `${baseApiUrl}admin-api/${apiStage}/orgs`
                },
                {
                    name: ConnectApi.USER_API,
                    endpoint: `${baseApiUrl}user-api/${apiStage}/orgs`
                },
                {
                    name: ConnectApi.EQUIPMENT_API,
                    endpoint: `${baseApiUrl}equipment-api/${apiStage}/orgs`
                },
                {
                    name: ConnectApi.CHEMICAL_API,
                    endpoint: `${baseApiUrl}chemicals-api/${apiStage}/orgs`
                },
                {
                    name: ConnectApi.REPORT_API,
                    endpoint: `${baseApiUrl}report-api/${apiStage}`
                },
                {
                    name: ConnectApi.NATIONAL_MAP_API,
                    endpoint: `${baseApiUrl}national-map-api`
                },
            ]
        }
    });
}
