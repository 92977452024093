export const ORG_STATUS = {
    NOT_ASSIGNED : 'Not Assigned'
}

export const USER_TYPE = {
    USER: '0',
    ADMIN: '1'
}

export const USER_STATUS = {
    ACTIVE : 'ACTIVE',
    INACTIVE : 'INACTIVE',
}