import React, { Component } from "react";
import { getOrgList, setBreadcrumb } from '../../../actions/commonActions';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from "redux";
import { Form, Input, Icon, Button, message } from "antd";
import Search from '../../../components/Search';
import * as SearchUtils from '../../../utils/search-utils';
import { ListWidget } from "../../../components/ListWidget";
import { ContractorListItem } from "../ContractorListItemBuilder";
import { getContractors } from "../actions/contractorActions";
import { setSelectedMenu } from '../../../actions/commonActions';

class ContractorListView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchMatches: null,
            selectedOrg: null,
            loading: false,
            users: []
        };

        this.props.setBreadcrumb("Contractors", [
            { route: '', label: 'Contractors' }
        ]);
    }

    componentDidMount = () => {
        this.props.getOrgList();
    }

    filterOrgs = () => {
        return SearchUtils.filter(this.props.orgList ? this.props.orgList : [], "org_id", this.state.searchMatches);
    }

    filterSearchOptions() {
        const orgs = this.filterOrgs();
        const optionsMap = { title: "Organisations", children: [] };
        orgs.forEach((org) => {
            optionsMap.children.push({ title: org.name, id: org.org_id });
        });

        return [optionsMap];
    }

    onOrgSearch = (phrase) => {
        let matches = SearchUtils.search(this.props.orgIndex, phrase);
        this.setState({ searchMatches: matches });
    }

    getUsersForListView = () => {
        const orgList = this.props.orgList
        let userList = this.state.users.map((user) => {
            let builder = new ContractorListItem.Builder(user, orgList);
            return builder.build();
        });
        return userList;
    }

    onSearchSelect = (orgId) => {
        this.setState({ selectedOrg: orgId })
    }

    onSearch = async (e) => {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (err) {
                return;
            }
            const { businessName, email, firstName, lastName, abn } = values;
            const { selectedOrg } = this.state;
            if (!businessName && !email && !firstName && !lastName && !selectedOrg && !abn) {
                message.error("Please enter at least one search criteria")
                return;
            }
            if (selectedOrg) {
                values.selectedOrg = selectedOrg;
            }
            this.setState({ loading: true });
            const users = await getContractors(values);
            
            this.setState({ loading: false, users });
        });
    }

    onReset = () => {
        this.props.form.resetFields();
        this.setState({ users: [], selectedOrg: null });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return(
            <div>
                <Form layout="inline" onSubmit={this.onSearch}>
                    <Form.Item>
                        {getFieldDecorator('businessName',
                        )(
                            <Input
                                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder="Business Name"
                            />,
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('email',
                        )(
                            <Input
                                prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                type="email"
                                placeholder="Email"
                            />,
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('firstName',
                        )(
                            <Input
                                prefix="F"
                                placeholder="First Name"
                            />,
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('lastName',
                        )(
                            <Input
                                prefix="L"
                                placeholder="Last Name"
                            />,
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('abn',
                        )(
                            <Input
                                prefix="A"
                                placeholder="ABN"
                            />,
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('selectedOrg',
                        )(
                            <Search
                                searchText={"Select Organisation"}
                                handleSearch={(value) => this.onOrgSearch(value)}
                                handleSelect={(value) => this.onSearchSelect(value)}
                                options={this.filterSearchOptions()}
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Search
                            </Button>
                        <Button style={{ marginLeft: '10px' }} type="primary" onClick={this.onReset} >
                            Reset
                        </Button>
                    </Form.Item>
                </Form>
                <div className="pure-g" style={{ marginTop: 10 }}>
                    <div className="pure-u-1">
                        <ListWidget data={this.getUsersForListView()} loading={this.state.loading} />
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state, props) {
    return {
        orgList: state.getIn(['orgState', 'orgList']),
        orgIndex: state.getIn(['orgState', 'orgIndex']),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setBreadcrumb: bindActionCreators(setBreadcrumb, dispatch),
        getOrgList: bindActionCreators(getOrgList, dispatch),
        setSelectedMenu: bindActionCreators(setSelectedMenu, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form.create()(ContractorListView)));