// @flow

import React, { Component } from 'react';
import { withRouter, Link, Switch, Route, Redirect } from 'react-router-dom';

import { Layout, Menu, Icon } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import HomePage from '../../../containers/HomePage';

import AppHeader from '../../../components/AppHeader';
import { AppContent } from '../../../components/AppContent';
import SidePanel from '../../../components/SidePanel';
import SideDrawer from '../../../components/SideDrawer';
import { isMobile } from '../../../utils/common-utils';
import { toggleSidePanel } from '../../../actions/commonActions';
import { logout } from '../../login/actions/loginActions';

import { setBreadcrumb } from '../../../actions/commonActions';
import ChemicalHome from '../../chemicals/ChemicalHome';
import EquipmentHome from '../../equipment/EquipmentHome';
import InspectionHome from '../../inspection/InspectionHome';
import CourseHome from '../../course/CourseHome';
import OrgHome from '../../organisations/OrgHome';
import ReportHome from '../../report/ReportHome';
import UsersHome from '../../users/UsersHome';
import JobPlannerHome from '../../jobPlanner/JobPlannerHome';
import EHSTips from '../../ehs-tips/EHSTips';
import CertificationHome from '../../external-certification/CertificationHome';
import ContractorHome from '../../contractors/ContractorHome';

const { Content } = Layout;
const SubMenu = Menu.SubMenu;

export class MainDashboardPage extends Component {

  constructor(props) {
    super(props);
    this.state = { sideMenuItems: [] };
  }
  componentDidMount = async () => {
    this.setSideMenuItems();
  }

  componentDidUpdate = async (prevProps) => {
    if (this.props.location.pathname === '/') {
      this.props.setBreadcrumb("");
    }
  }

  onMenuclicked = () => {
    if (isMobile()) {
      this.props.toggleSidePanel(false);
    }
  };

  buildMenuItems = (items) => {
    return items.map((i) => {
      if (i.children) {
        return (
          <SubMenu key={(i.key) ? i.key : i.name.toLowerCase()
          } title={< span > <Icon type={i.icon} /> <span>{i.name}</span></span >}>
            {this.buildMenuItems(i.children)}
          </SubMenu >
        )
      }
      else {
        return (
          <Menu.Item key={(i.key) ? i.key : i.name.toLowerCase()}>
            <Link to={i.url} onClick={this.onMenuclicked}>
              <Icon type={i.icon} />
              <span>
                {i.name}
              </span>
            </Link>
          </Menu.Item>
        );
      }
    });
  }

  /**
   * Construct the side menu items
   */
  setSideMenuItems = async () => {
    let menuItemsList = [
      { name: 'Dashboard', icon: 'dashboard', url: '/dashboard', key: 'dashboard' },
      { name: 'Chemicals', icon: 'experiment', url: '/chemicals', key: 'chemicals' },
      { name: 'Courses', icon: 'project', url: '/courses', key: 'courses' },
      { name: 'Equipment', icon: 'tool', url: '/equipment', key: 'equipment' },
      { name: 'Inspections', icon: 'search', url: '/inspections', key: 'inspections' },
      { name: 'Job Planner', icon: 'calendar', url: '/job_planner', key: 'jobPlanner' },
      { name: 'Audubon', icon: 'profile', url: '', 
        children: [
          { name: 'Documents', icon: '', url: '/audubon/audubon_documents', key: 'audubon_documents'},
          { name: 'Certifications', icon: '', url: '/audubon/audubon_inspections', key: 'audubon_inspections'}
        ]},
      { name: 'Organisations', icon: 'bank', url: '/organisations', key: 'organisations' },
      { name: 'Report', icon: 'area-chart', url: '/report', key: 'report' },
      { name: 'Users', icon: 'user', url: '/users', key: 'users' },
      { name: 'EHS Tips', icon: 'star', url: '/ehs-tips', key: 'ehs-tips' },
      { name: 'Contractors', icon: 'user', url: '/contractors', key: 'contractors' }
    ];
    const sideMenuItems = this.buildMenuItems(menuItemsList);
    await this.setState({ sideMenuItems });
  }

  getView = (route) => {
    let view;
    switch (route.match.params.page) {
      case 'dashboard':
        view = (<HomePage />)
        break;
      case 'chemicals':
        view = <ChemicalHome />;
        break;
      case 'equipment':
        view = <EquipmentHome />;
        break;
      case 'inspections':
        view = <InspectionHome />;
        break;
      case 'courses':
        view = <CourseHome />;
        break;
      case 'organisations':
        view = <OrgHome />;
        break;
      case 'report':
        view = <ReportHome />;
        break;
      case 'users':
        view = <UsersHome />;
        break;
      case 'job_planner':
        view = <JobPlannerHome />;
        break;
      case 'audubon':
        view = <CertificationHome />; 
        break;
      case 'ehs-tips':
        view = <EHSTips />;
        break;
      case 'contractors':
        view = <ContractorHome />;
        break;
      default:
        view = (<HomePage />)
        break;
    }
    return view;
  }

  renderSidePanel = () => {
    if (isMobile()) {
      return (
        <SideDrawer>
          {this.state.sideMenuItems}
          <Menu.Item key="logout" onClick={this.props.logout}>
            <Icon type="logout" />
            {'Logout'}
          </Menu.Item>
        </SideDrawer>
      )
    }
    else {
      return (
        <SidePanel>
          {this.state.sideMenuItems}
        </SidePanel>
      )
    }
  }

  render() {
    return (
      <Layout style={{ minHeight: '100vh' }}>
        {this.renderSidePanel()}
        <Content>
          <Layout>
            <AppHeader logout={this.props.logout} />
            <AppContent>
              <Switch>
                <Route exact path='/' render={this.getView} />
                <Route path={'/:page/:param'} render={this.getView} />
                <Route path={'/:page'} render={this.getView} />
                <Redirect to='/' />
              </Switch>
            </AppContent>
          </Layout>
        </Content>
      </Layout>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    orgList: state.getIn(['loginUserState', 'orgList']),
    isAdmin: state.getIn(['loginUserState', 'isAdmin']),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    logout: bindActionCreators(logout, dispatch),
    toggleSidePanel: bindActionCreators(toggleSidePanel, dispatch),
    setBreadcrumb: bindActionCreators(setBreadcrumb, dispatch),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainDashboardPage));



