import { getApi, ConnectAdminApi } from "../../../utils/api-utils";

export async function getContractors(queryStringParameters) {
  const result = await getApi(ConnectAdminApi.CONTRACTOR_API, `/get-filtered-contractors`, { queryStringParameters });
  return result.allUserList;
}

export async function getContractorDetails(queryStringParameters) {
  const result = await getApi(ConnectAdminApi.CONTRACTOR_API, `/get-contractor-details`, { queryStringParameters });
  return result;
}